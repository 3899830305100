<template>
    <div>
        <div class="pb-6 diagonal pb-8 pt-0 pt-md-5" >
            <div class="row">
            </div>
        </div>
        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-3  col-lg-3">
                    <div class="card shadow">
                        <div class="card-header border-0">

                            <div class="text-center">
                                <h3>
                                    {{ guestInfo.first_name }} {{ guestInfo.last_name }}<span class="font-weight-light"></span>
                                </h3>
                                <hr class="my-2"/>
                                <div class="h4 mt-4">
                                    Vlastná úroveň pre hosťa
                                </div>
                                <a-select class="w-70" :options="levels" v-model="guestInfo.customlevel"></a-select>
                                <div class="h4 mt-3">
                                    {{guestInfo.level.title}}
                                </div>
                                <div class="h5 font-weight-300 mb-4">
                                    {{guestInfo.n}} odubytovaných nocí
                                </div>
                                <a v-bind:href="'mailto:'+ guestInfo.email">{{ guestInfo.email }}</a>

                                <hr class="my-4"/>

                                <button class="btn btn-primary" @click="saveLevel">Uložiť</button>
                            </div>
                        </div> </div>
                </div>  <div class="col-xl-9  col-lg-9">
                    <div class="card shadow">
                            <div class="table-responsive">
                            <a-table
                                    :columns="columns"
                                    :dataSource="data"
                                    :loading="loading"
                                    :rowKey="record => record.order_id"
                                    class="table-flush">

                                <template slot="user_role" slot-scope="user">
                                    <a class="text-left text-lowercase" :href="'role/'+ role" :key="role+index"
                                       v-for="(role,index) in JSON.parse(user.type_id)"> {{roleops[role]}}<span
                                            v-if="index+1 < JSON.parse(user.type_id).length">, </span></a>
                                </template>
                                <template slot="date" slot-scope="datedata">
                                    <span>{{formatDate(datedata)}}</span>
                                </template>
                                <template slot="HotidDetail" slot-scope="hotdat">
                                    <span v-if="hotdat>0">{{ hotdat }}</span>
                                    <span v-else>Voucher</span>
                                </template>
                                <template slot="detail" slot-scope="detailed">
                                     <a-tooltip v-if="detailed" placement="rightBottom" :title="detailed[0].auto_note.replace(/(\r\n|\r|\n){2}/g, '$1').replace(/(\r\n|\r|\n){3,}/g, '$1\n')" :getPopupContainer="getPopupContainer">
                                        <a-button>Detaily objednávky</a-button>
                                    </a-tooltip>


                                </template>
                                <temlate slot="details" slot-scope="detailed">
                                    <div v-for="(room,index) in detailed[0].rows" v-bind:class="[room.canceled ? 'red' : 'green']">{{formatDateN(room.date_from)}} - {{formatDateN(room.date_to)}} / {{room.room_type_code}}</div>
                                </temlate>


                                <template slot="name" slot-scope="user">
                                    <base-dropdown class="dropdown" position="right">
                                        <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </a>
                                        <template>
                                            <sidebar-item :link="{name: 'Detail', path: '/guest/' + user.uid}"/>
                                        </template>
                                    </base-dropdown>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {dataService} from "../_services";
    import {Popconfirm} from "ant-design-vue"
    import {Button, Input, Icon, Tooltip, Select} from 'ant-design-vue';
    import moment from 'moment'

    export default {
        name: "guestsreservations",
        props: ['id'],
        components: {
            "a-popconfirm": Popconfirm,
            "a-button": Button,
            "a-icon": Icon,
            "a-input": Input,
            "a-select": Select,
            "a-tooltip": Tooltip
        },
        data() {
            return {
                data: [],
                searchText: '',
                searchInput: null,
                roleops: [],
                guestInfo: '',
                colorsto: JSON.parse(localStorage.colors),
                loading: false,
                columns: [
                    {
                        title: 'Číslo rezervácie',
                        dataIndex: 'order_id',
                        width: '20%',
                    },
                    {
                        title: 'Objednané',
                        dataIndex: 'created',
                        scopedSlots: {customRender: 'date'},
                        width: '20%',
                    },
                    {
                        title: 'Posledná zmena',
                        scopedSlots: {customRender: 'date'},
                        dataIndex: 'modified',
                    },
                    {
                        title: 'HoresID',
                        scopedSlots: {customRender: 'HotidDetail'},
                        dataIndex: 'resnumb',
                    },
                    {
                        title: 'Detail',
                        scopedSlots: {customRender: 'detail'},
                        dataIndex: 'details',
                    },   {
                        title: 'Status',
                        scopedSlots: {customRender: 'details'},
                        dataIndex: 'details',
                    },
                    {
                        align: 'right',
                        scopedSlots: {customRender: 'name'},
                    }
                ],
            };
        },

        mounted() {
            this.$nextTick(() => {
                this.fetch();

            })

        },
        methods: {
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];
                console.log(this.searchText);
            },
            formatDate: function(dt){

                return moment.unix(dt).format('D.M.YYYY h:mm')


            },    formatDateN: function(dt){

                return moment(dt,'YYYY-MM-DD').format('D.M.YYYY')


            },
            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
            saveLevel: function () {
                dataService.axiosPost(this.$options.name,this.guestInfo).then(results => {

                });
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch(this.$options.name+"/"+this.id).then(results => {
                    this.data = results.orders;
                    this.guestInfo = results.guestInfo;
                    this.levels = results.levels;
                    this.loading = false;
                                    });
            }

        },
    };
</script>

<style>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }

    .highlight {
        background-color: rgb(255, 192, 105);
        padding: 0px;
    }
    .ant-tooltip-inner
    {white-space: pre-line;font-size:12px;}


    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination5-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination5-item.ant-pagination5-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination5-item-link.ant-pagination5-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
